import React, { Component, type ComponentType, type ReactNode } from 'react';

import PropTypes from 'prop-types';

import {
	type CurrentMessage,
	type ComponentType as EngagementComponentType,
} from '../../common/types/message';
import { type State as EngagementStoreState } from '../engagement-store';

type State = {
	message: CurrentMessage | null;
};

export type Props = {
	engagementId?: string;
	children?: ReactNode;
};

export default (
	InnerComponent: ComponentType<any>,
	componentType: EngagementComponentType,
): ComponentType<any> => {
	function isTargetComponent(message: CurrentMessage): boolean {
		return message.componentType === componentType;
	}

	function computeNewState(state: EngagementStoreState): State {
		const current = state.current;
		if (current && isTargetComponent(current)) {
			let message;
			switch (current.componentType) {
				case 'contextualSurvey':
					message = {
						...current,
						aaid: state.config.aaid,
						cloudId: state.config.cloudId,
						stargateUrl: state.config.stargateUrl,
						product: state.config.product,
						orgId: state.config.orgId,
						workspaceId: state.config.workspaceId,
					};
					break;

				default:
					message = current;
			}

			return {
				message,
			};
		}

		return { message: null };
	}

	// eslint-disable-next-line @repo/internal/react/no-class-components
	class StoreWrapper extends Component<Props, State> {
		context: any;
		static contextTypes = {
			subscribeEngagementState: PropTypes.func,
		};

		readonly state: Readonly<State> = {
			message: null,
		};

		UNSAFE_componentWillMount() {
			if (this.canSubscribe()) {
				this.unsubscribe = this.subscribeToStore();
			}
		}

		componentWillUnmount() {
			if (this.unsubscribe) {
				this.unsubscribe();
			}
		}

		onStoreChange = (state: EngagementStoreState) => {
			this.setState(computeNewState(state));
		};

		unsubscribe!: () => void;

		canSubscribe(): boolean {
			return this.context && this.context.subscribeEngagementState;
		}

		subscribeToStore() {
			return this.context.subscribeEngagementState(this.onStoreChange);
		}

		render() {
			const { children, engagementId } = this.props;
			const { message } = this.state;

			if (!message) {
				if (componentType === 'inlineDialog') {
					return children || null;
				}

				return null;
			}

			if (engagementId && message !== null && message.target && engagementId !== message.target) {
				return null;
			}

			const messageKey = `${message.messageId}.${message.variationId}.${message.componentId}`;

			// Key is useful here to make sure that when the currently active
			// messages changes, the old component is unmounted (therefore
			// firing an analytic event for hiding the component) and mounting
			// a new component for the new message (firing a shown event for
			// the new message). Not using key would allow React to apply an
			// optimization that would reuse the current component for a new
			// message which would not call the normal mount and unmount
			// lifecycle methods.
			return <InnerComponent key={messageKey} {...this.props} {...message} />;
		}
	}

	return StoreWrapper;
};
